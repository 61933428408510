.cardhover{
     background-color: var(--white);

}
.cardhover:hover{
     background-color:var(--white);
     transform: scale(1.01);
     height: auto;
     
}
.card-body {
  flex: 1 1 auto;
  padding: 0 !important;
  color: var(--bs-card-color);
}
.cardhover {
     height: auto; /* Set a fixed height for all cards */
     display: flex;
     flex-direction: column;
     margin-top: 12px;
     margin-right: 22px;
     background-color: var(--gray);
     
   }
   
   .cardhover .card {
     flex: 1; /* Make all cards have equal height within the container */
     margin-bottom: 15px; /* Add margin between cards */
   }
  .cardhover .list-group-item {
    display: none; /* Hide list items by default */
    cursor: pointer; /* Change cursor on hover (optional) */
    height: auto;
    background-color: var(--gray);
    color: var(--black);
    font-weight:600 ;
    padding-left: 22px !important;
    border: none !important;
   
  }
  
  .cardhover .list-group-item:hover {
    background-color: var(--orange); /* Background color on hover */
    color: var(--white); /* Text color on hover */
    cursor: pointer; /* Change cursor on hover (optional) */
    height: auto;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    box-shadow: 0px 2px 0px 0px rgb(161, 160, 160);
    padding-left: 22px;
    border: none !important;
    transition: all 0.1s ease;
  }
  
  /* Additional styles for showing list items on card hover */
  .cardhover:hover .list-group-item {
    display: block;
    height: auto;
  }
  
  .solution_WhatWeDo{
    color: var(--white) !important;
  }
  
  @media screen and (max-width: 767px) {
     .bnrImg {
      height: 40vh !important;
    }
    .bnrImg img{
      height: 40vh !important;
      border-bottom-left-radius: 10px 10px !important;
      border-bottom-right-radius: 10px 10px !important;
    }
  .industries-section-hadding{
    text-align: left !important;
  } 
  .contact_us {
    padding: 26px 0px !important;
  }
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .bnrImg {
      height: 50vh !important;
    }
    .bnrImg img{
      height: 50vh !important;
      border-bottom-left-radius: 10px 10px !important;
      border-bottom-right-radius: 10px 10px !important;
    }
    .industries-section-hadding{
      text-align: left !important;
    }
     .about_us_img img{
      width: 60%;
     }
  }

  @media screen and (min-width: 1000px) and (max-width: 1400px) {
    .bnrImg img{
      
      border-bottom-left-radius: 10px 10px !important;
      border-bottom-right-radius: 10px 10px !important;
    }
    .industries-section-hadding{
      text-align: left !important;
    } 
  }

