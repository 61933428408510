@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --orange: #fb8b23;
  --gray: #aba9a5;
  --white: #fff;
  --h2heading: #f5f5f5;
  --paragrap: #333333;
  --footerbackground: #727272;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #fcfcfc;
}
a:hover {
  text-decoration: none;
}

:root {
  scroll-behavior: unset !important;
}
.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  position: relative;
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, Sans-Serif;
}

.whatWeDo {
  text-align: left;
}
.whatWeDo h1 {
  font-size: 55px;
  color: var(--black) !important;
  font-weight: 600;
}
@media (max-width: 320px) {
  .whatWeDo h1 {
    font-size: 35px;
    color: var(--black) !important;
    font-weight: 600;
  }
  }

.whatWeDo p {
  font-size: 22px;
  text-align: justify;
  color: var(--black);
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
}
.whatWeDo .button-1 {
  color: var(--white) !important;
  background-color: var(--orange) !important;
}
.whatWeDo .button-1:hover {
  background-position: 100%;
  color: var(--orange) !important;
  background-color: var(--white) !important;
  border: 2px solid var(--orange);
}

@media screen and (max-width: 2560px) {
  #industryHr {
    border: none;
    background-color: var(--orange);
    opacity: 1;
    height: 5px;
    position: absolute;
    top: 0;
    width: 50%;
    right: 0;
  }
}

@media screen and (max-width: 1440px) {
  #industryHr {
    border: none;
    background-color: var(--orange);
    opacity: 1;
    height: 5px;
    position: absolute;
    top: 0;
    width: 50%;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  #industryHr {
  display:none;
  }
}

  #serviceHr {
    border: none;
    background-color: var(--orange);
    opacity: 1;
    height: 5px;
    position: absolute;
    top: 0;
    width: 50%;
    left: 0 !important;
  }


@media screen and (max-width: 1024px) {
  #serviceHr {
  display:none;
  }
}

/* News  */

.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: var(--white);
  width: 325px;
  height: 425px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.post-module:hover,
.hover {
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.post-module:hover .thumbnail img,
.hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.6;
}
.post-module .thumbnail {
  background: #000000;
  height: 400px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: var(--orange);
  width: 55px;
  height: 55px;
  padding: 5.5px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: var(--white);
  font-weight: 700;
  text-align: center;
  --webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: var(--white);
  width: 100%;
  padding: 20px;
  --webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: var(--orange);
  padding: 10px 15px;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: var(--black);
  font-size: 26px;
  font-weight: 700;
}
.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  display: none;
  color: var(--gray);
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: var(--gray);
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: var(--gray);
  text-decoration: none;
}
.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.column .demo-title {
  /* margin: 0 0 15px; */
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.info {
  width: 300px;
  margin: 11px auto;
  color: var(--white);
  font-size: 22px;
  text-align: justify;
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
}
.info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: var(--white);
}
.info span {
  color: #666666;
  font-size: 12px;
}
.info span a {
  color: var(--white);
  text-decoration: none;
}
.info span .fa {
  color: var(--black);
}

.ourroootSection {
  background-color: var(--white) !important;
  color: var(--black);
}
.aboutRoot p {
  font-size: 22px;
  text-align: justify;
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
}
.aboutRoot {
  background-color: var(--orange);
}

.img-hover-effect:hover {
  /* Add your desired styles here */
  opacity: 0.9; /* Decrease opacity on hover */
  transform: scale(1.05); /* Scale up the image on hover */
  transition: 0.3s ease-in-out; /* Add a smooth transition effect */
}

.img-zoom-out:hover {
  /* Scale down the image on hover */
  transform: scale(0.95);
  opacity: 0.8; /* Decrease opacity on hover */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add a smooth transition effect for both scale and opacity */
}

.container {
  width: 1000px;
  position: relative;
  justify-content: space-between;
}

ddd {
  position: relative;
  cursor: pointer;
  --bs-card-border-color: none;
  color: var(--white);
}

.face {
  width: 400px;
  height: 200px;
  transition: 0.5s;
  overflow: hidden;
}

.face.face1 {
  position: relative;
  background: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
  background: url("https://i.ibb.co/pzGysVS/b.webp") rgba(255, 255, 255, 0.1);
  height: 300px;
  border-radius: 5px;
}

.container .card:hover .face.face1 {
  transform: translateY(0);
}

.face.face1 .content {
  opacity: 1;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content {
  opacity: 1;
}

.face.face1 .content img {
  max-width: 100px;
}

.face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: var(--white);
  text-align: center;
  font-size: 1.5em;
}

.face.face2 {
  position: relative;
  background: black;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.container .card:hover .face.face2 {
  transform: translateY(0);
  height: auto;
}

.face.face2 .content p {
  margin: 0;
  padding: 0;
}

.face.face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.face.face2 .content a:hover {
  background: var(--h2heading) !important;
  color: var(--white) !important;
}
@media (max-width: 767px) {
}

@media (min-width: 768px) {
}

.solution-cutomized {
  height: 270px;
  margin: 10px 60px !important;
  width: 590px !important;
}
.section_paddinging {
  background-color: var(--footerbackground);
}

/* testimonilas */
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ececec;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
}
#shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}
.img-circle {
  border-radius: 50%;
  vertical-align: middle;
  max-width: 90px;
  min-height: 90px;
  transform-style: preserve-3d;
  margin: 0 auto 17px;
}
#customer-testimonoals {
  margin-top: 6%;
}
.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
  background: linear-gradient(100deg, #ff8066, #ff918d);
  /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
}
#customer-testimonoals .item {
  text-align: center;
  padding: 50px;
  margin-bottom: 0px;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}
#customer-testimonoals .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #56423d;
  transform: translate3d(0px, -50%, 0px) scale(0.8);
}
.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.owl-carousel .owl-dots .owl-dot span {
  /* background: #eaa400; */
  background: #56423d;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
}

.heading-homepage {
  font-size: 55px !important;
}
@media screen and (max-width: 767px) {
  .whatWeDo h1 {
    font-size: 35px;
    color: var(--black) !important;
    font-weight: 600;
  }
  .whatWeDo p {
    font-size: 20px;
    text-align: justify;
    color: var(--black);
    font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
  }

  .whatWeDo p {
    font-size: 18px;
    text-align: justify;
    color: var(--black);
    font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
  }
  .heading-homepage {
    font-size: 35px !important;
  }
}

.custom-btn {
  width: 145px;
  height: 40px;
  color: var(--orange);
  border-radius: 5px;
  padding: 10px 25px Im !important;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.hori:hover {
  border-bottom: #ececec solid;
  padding-bottom: 5px;
}
.hori1 {
  border-bottom: #ececec solid;
  padding-bottom: 5px;
}

.btn-7 {
  background: white;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-7 span {
  position: relative;
  display: block;
  font-weight: 600;
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: large;
  width: 100%;
  height: 100%;
}
.btn-7:before,
.btn-7:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: white;
  transition: all 0.3s ease;
}
.btn-7:before {
  height: 0%;
  width: 2px;
}
.btn-7:after {
  width: 0%;
  height: 2px;
}
.btn-7:hover {
  color: white;
  background: transparent;
}
.btn-7:hover:before {
  height: 100%;
}
.btn-7:hover:after {
  width: 100%;
}
.btn-7 span:before,
.btn-7 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: white;
  transition: all 0.3s ease;
}
.btn-7 span:before {
  width: 2px;
  height: 0%;
}
.btn-7 span:after {
  height: 2px;
  width: 0%;
}
.btn-7 span:hover:before {
  height: 100%;
}
.btn-7 span:hover:after {
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 950px) {
}

@media screen and (min-width: 425px) and (max-width: 767px) {
}

@media screen and (min-width: 320px) and (max-width: 424px) {
}
/* CSS for screens with a width up to 419px (small phones) */
@media screen and(width: 425px) {
}

.homeProduct {
  display: flex !important;
  flex-direction: column !important;
}
.homeproductcard:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.homeproductcard img:hover {
  /* box-shadow: -17px 23px 21px 0px rgba(41, 41, 41, 0.1),17px 21px 15px -3px rgba(96, 94, 94, 0.1); */
  /* -webkit-transform: scale(1.01); */
  /* transform: scale(1.01); */
  overflow: hidden;
  zoom: 1.05;
}
.btn-primary {
  --bs-btn-border-color: none !important;
}
.btn-primary:hover {
  --bs-btn-border-color: none !important;
  opacity: 0.95 !important;
}

/* Palletizer solution */

.trendcard{
  width:650px;
}

.conceptCard{
  width:435px;
}
.conceptContent{
  text-align: justify;
  border: 1px solid white;
  border-radius: 0px 35px 0px 35px;
 background-color: var(--orange);
 color: white;
 line-height: 20px;
}

.newDes{
  font-size: 22px;
  font-weight: 400;
  text-align: justify;
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
}
.newRead{
  color: #000000;
}
.newRead :hover{
color: #fb8b23;
}
.term-list{
  text-align: justify;
  font-size: 16px;
}