  
  .about {
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;
  }
  .about .bg_links {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    backdrop-filter: blur(5px);
    position: absolute;
  }
  .about .logo {
    width: 40px;
    height: 40px;
    z-index: 9;
    background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 10px 7px;
    opacity: 0.9;
    transition: all 1s 0.2s ease;
    bottom: 0;
    right: 0;
  }
  .about .social {
    opacity: 0;
    right: 0;
    bottom: 0;
  }
  .about .social .icon {
    width: 100%;
    height: 100%;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    display: flex;
    transition: all 0.2s ease, background-color 0.4s ease;
    opacity: 0;
    border-radius: 100%;
  }
  .about .social.portfolio {
    transition: all 0.8s ease;
  }
  .about .social.portfolio .icon {
    background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
  }
  .about .social.dribbble {
    transition: all 0.3s ease;
  }
  .about .social.dribbble .icon {
    background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
  }
  .about .social.linkedin {
    transition: all 0.8s ease;
  }
  .about .social.linkedin .icon {
    background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
  }
  .about:hover {
    width: 105px;
    height: 105px;
    transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
  }
  .about:hover .logo {
    opacity: 1;
    transition: all 0.6s ease;
  }
  .about:hover .social {
    opacity: 1;
  }
  .about:hover .social .icon {
    opacity: 0.9;
  }
  .about:hover .social:hover {
    background-size: 28px;
  }
  .about:hover .social:hover .icon {
    background-size: 65%;
    opacity: 1;
  }
  .about:hover .social.portfolio {
    right: 0;
    bottom: calc(100% - 40px);
    transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
  }
  .about:hover .social.portfolio .icon:hover {
    background-color: #698fb7;
  }
  .about:hover .social.dribbble {
    bottom: 45%;
    right: 45%;
    transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
  }
  .about:hover .social.dribbble .icon:hover {
    background-color: #ea4c89;
  }
  .about:hover .social.linkedin {
    bottom: 0;
    right: calc(100% - 40px);
    transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
  }
  .about:hover .social.linkedin .icon:hover {
    background-color: #0077b5;
  }
  
  .wrapper {
    width: 100vw;
    margin: 0 auto;
    height: 400px;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width: 767px) {
    .wrapper {
      height: 700px;
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 767px) {
    .content {
      flex-direction: column;
    }
  }
  .values{
    /* background-image: linear-gradient(to right top, #63b2be, #53c3b4, #71d095, #abd66c, #f1d34d); */
  }
  .valuecard {
    width: 100%;
    max-width: 315px;
    min-width: 220px;
    height: 270px;
    background-color: #fff;
    margin: 10px 22px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #0000;
  }
  
  .material-icons.md-18 {
    font-size: 18px;
  }
  
  .material-icons.md-24 {
    font-size: 24px;
  }
  
  .material-icons.md-36 {
    font-size: 36px;
  }
  
  .material-icons.md-48 {
    font-size: 48px;
  }
  
  .valuecard .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 10px;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  .valuecard .text {
    width: 85%;
    margin: 0 auto;
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    color: black;
    font-weight: 200;
    letter-spacing: 1px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
    text-align: justify;
  }
  
  .valuecard:hover {
    height: 350px;
  }
  
  .valuecard:hover .info {
    height: 90%;
  }
  
  .valuecard:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 40px;
  }
  
  .valuecard:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .valuecard:hover .icon i {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    transition: all 0.3s ease;
  }

  .aboutOverview{
    font-size: 22px;
    text-align: justify;
    color: black;
    font-family: 'kuka-bulo', Calibri, Segoe UI, Helvetica, Arial, sans-serif;
  }
  .vision p{
    color: black;
    font-size: 22px;
    font-family: 'kuka-bulo', Calibri, Segoe UI, Helvetica, Arial, sans-serif;
    line-height:40px ;
  }
  .mission p{
    color: black;
    font-size: 22px;
    font-family: 'kuka-bulo', Calibri, Segoe UI, Helvetica, Arial, sans-serif;
    line-height: 40px;
  }