html,
body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: sans-serif;
  background-color: #fff;
  height: 80vh;
}

h1,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.section {
  padding: 4rem 2rem;
}

.section .error {
  font-size: 150px;
  color: var(--orange);
  text-shadow: 1px 1px 1px #fff, 2px 2px 1px #fff, 3px 3px 1px var(--gray),
    4px 4px 1px var(--gray), 5px 5px 1px var(--gray), 6px 6px 1px var(--gray),
    7px 7px 1px var(--gray), 8px 8px 1px var(--gray), 25px 25px 8px var(--gray);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #222;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.back-home:hover {
  background: var(--orange);
  color: var(--white);
}

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
}

.blog-card a {
  color: inherit;
}

.blog-card a:hover {
  color: #5ad67d;
}

.blog-card:hover .photo {
  transform: scale(1.3) rotate(3deg);
}

.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}

.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}

.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}

.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}

.blog-card .details a {
  text-decoration: dotted underline;
}

.blog-card .details ul li {
  display: inline-block;
}

.blog-card .details .author:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f007";
}

.blog-card .details .date:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f133";
}

.blog-card .details .tags ul:before {
  font-family: FontAwesome;
  content: "\f02b";
  margin-right: 10px;
}

.blog-card .details .tags li {
  margin-right: 2px;
}

.blog-card .details .tags li:first-child {
  margin-left: -4px;
}

.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}

.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}

.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}

.blog-card .description .read-more {
  text-align: right;
}

.blog-card .description .read-more a {
  color: #5ad67d;
  display: inline-block;
  position: relative;
}

.blog-card .description .read-more a:after {
  content: "\f061";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}

.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}

.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}

.blog-card p:first-of-type {
  margin-top: 1.25rem;
}

.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #5ad67d;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}

.blog-card:hover .details {
  left: 0%;
}

@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 700px;
  }

  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }

  .blog-card .description {
    flex-basis: 60%;
  }

  .blog-card .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .blog-card.alt {
    flex-direction: row-reverse;
  }

  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }

  .blog-card.alt .details {
    padding-left: 25px;
  }
}

.owncrousal {
  display: block;
}

.right_conatct_social_icon {
  background: var(--orange);
}
.contact_us {
  background-color: #f1f1f1;
  padding: 120px 0px;
  display: flex;
  justify-content: center;
}

.contact_inner {
  background-color: #fff;
  position: relative;
  box-shadow: 20px 22px 44px #cccc;
  border-radius: 25px;
}
.contact_field {
  padding: 60px 340px 90px 100px;
}
.right_conatct_social_icon {
  height: 100%;
}

.contact_field h3 {
  color: #000;
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact_field p {
  color: #000;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 35px;
  color: var(--black) !important;
  font-family: kuka-bulo, Calibri, Segoe UI, Helvetica, Arial, sans-serif !important;
  font-size: 22px !important;
  font-weight: 500;
}
.contact_field .form-control {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
}
.contact_field .form-control:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid var(--orange) !important;
}
.contact_field .form-control::placeholder {
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 1px;
}

.contact_info_sec {
  position: absolute;
  background-color: var(--footerbackground);
  color: var(--white);
  right: 1px;
  top: 18%;
  height: 340px;
  width: 400px;
  padding: 40px;
  border-radius: 25px 0 0 25px;
}
.contact_info_sec h4 {
  letter-spacing: 1px;
  padding-bottom: 15px;
  font-weight: 800;
}

.info_single {
  margin: 30px 0px;
}
.info_single i {
  margin-right: 15px;
  font-weight: 600;
}
.info_single span {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

button.contact_form_submit {
  background: var(--orange);
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}
.socil_item_inner li {
  list-style: none;
}
.socil_item_inner li a {
  color: #fff;
  margin: 0px 15px;
  font-size: 14px;
}
.socil_item_inner {
  padding-bottom: 10px;
}

.map_sec {
  padding: 50px 0px;
}
.map_inner h4,
.map_inner p {
  color: #000;
  text-align: center;
}
.map_inner p {
  font-size: 13px;
}
.map_bind {
  margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}
.contact-paragrap {
  font-family: kuka-bulo, Calibri, Segoe UI, Helvetica, Arial, sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

/* Mobile Devices */
@media (max-width: 767px) {
  .contact_field {
    padding: 20px;
  }
  .contact_info_sec {
    display: none;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .contact_field {
    padding: 40px;
  }
  .contact_info_sec {
    display: none;
  }
}

/* Desktops and larger screens */
@media (min-width: 1024px) {
  .contact_field {
    padding: 60px 340px 90px 100px;
  }
  .contact_info_sec {
    display: block;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: none;
  }
}

.row {
  --bs-gutter-x: none !important;
}

.customer-slider {
  background-color: var(--orange);
}
.checklistColor {
  color: var(--orange);
  font-size: 50px;
}
.lits {
  font-size: 22px;
  text-align: justify;
  color: var(--white);
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
}

.whatWeoffer {
  text-align: left;
}
.whatWeoffer h1 {
  font-size: 55px;
  color: var(--white) !important;
}

@media (max-width: 320px) {
.whatWeoffer h1 {
  font-size: 35px;
  color: var(--white) !important;
}
}

.whatWeoffer p {
  font-size: 22px;
  text-align: justify;
  color: var(--white);
  font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
}

@media (max-width: 320px) {
  .whatWeoffer p {
    font-size: 18px;
    text-align: justify;
    color: var(--white);
    font-family: "kuka-bulo", Calibri, Segoe UI, Helvetica, Arial, sans-serif;
  }
  }
.whatWeoffer .button-1 {
  color: var(--white) !important;
  background-color: var(--orange) !important;
}
.whatWeoffer .button-1:hover {
  background-position: 100%;
  color: var(--orange) !important;
  background-color: var(--white) !important;
  border: 2px solid var(--orange);
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Reviews
===============
*/
main {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.title {
  text-align: center;
  margin-bottom: 4rem;
}
.underline {
  height: 0.25rem;
  width: 5rem;
  background: var(--footerbackground);
  margin-left: auto;
  margin-right: auto;
}
.container {
  width: 80vw;
}
.review {
  width: 100% !important;
  height: 500px !important;
  overflow: hidden;
  background: var(--orange);
  padding: 1.5rem 2rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  text-align: center;
  color: var(--orange);
  transition: transform 0.2s, box-shadow 0.2s;
}
.review:hover {
  transform: scale(1.05);
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.img-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.person-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}
.quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(25%);
  background: var(--orange);
  color: var(--white);
}
.img-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--clr-primary-5);
  color: var(--white) !important;
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}
.author {
  margin-bottom: 0.25rem;
  color: var(--white);
}
.job {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: var(--clr-primary-5);
  font-size: 0.85rem;
}
.info {
  margin-bottom: 0.75rem;
}
.prev-btn,
.next-btn {
  color: var(--white);
  font-size: 1.25rem;
  background: transparent;
  border-color: transparent;
  margin: 0 0.5rem;
  transition: var(--transition);
  cursor: pointer;
}
.prev-btn:hover,
.next-btn:hover {
  color: var(--footerbackground);
}
.random-btn {
  margin-top: 0.5rem;
  background: var(--white);
  color: var(--black);
  padding: 0.25rem 0.5rem;
  text-transform: capitalize;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
  cursor: pointer;
}
.random-btn:hover {
  background: var(--footerbackground);
  color: var(--white);
}

.icon {
  font-size: 4rem;
  color: red;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none !important;
}
.img-responsive {
  transition: transform 0.3s;
}

.img-responsive:hover {
  transform: translateX(-20px);
}

.alice-carousel__stage-item {
  margin: 0px 70px !important;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .alice-carousel__stage-item {
    margin: 0px 8px !important;
  }
  .info {
    width: 299px;
    padding: 4px;
  }
  .industries-section-hadding{
    text-align: left !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .alice-carousel__stage-item {
    width: 100% !important;
    margin: 0 27px !important;
    text-align: center;
    padding: unset !important;
  }

  @media screen and (min-width: 320px) and (max-width: 425px) {
    .alice-carousel__stage-item {
      width: 100% !important;
      margin: 0 2px !important;
      text-align: center !important;
      padding: unset !important;
    }
  }
  .alice-carousel__stage {
    padding: unset !important;
  }
  .info {
    width: 299px;
    padding: 4px;
  }
}
